import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO
      title="MERN Developer - Careers at D2i Technology"
      description="Join our team as a MERN Developer. 2-4 years of experience in building high-performance web applications with the MERN stack; experience with Laravel is a plus."
    />
    <section id="career-detail" className="career-detail">
      <div className="container" data-aos-todo="fade-up">
        <div className="section-title">
          <h1>MERN Developer</h1>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 mx-auto">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/careers">Careers</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  MERN Developer
                </li>
              </ol>
            </nav>
            <div className="job-card">
              <div className="job-header">
                <h2>Job Description</h2>
                <Link
                  to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                  target="_blank"
                  className="btn btn-primary"
                >
                  Apply Now
                </Link>
              </div>
              <p>
                We are seeking a MERN Developer with 2-4 years of experience in building high-performance web applications. Experience with Laravel and MySQL is a plus.
              </p>

              <h3>Requirements:</h3>
              <ul>
                <li>2-4 years of experience in web application development</li>
                <li>Proficiency in MongoDB</li>
                <li>Strong knowledge of the MERN stack (MongoDB, Express.js, React.js, Node.js)</li>
                <li>Experience with Laravel and MySQL is a plus</li>
                <li>Strong problem-solving skills and attention to detail</li>
                <li>Experience with unit testing frameworks (e.g. Jest, Mocha) is a plus</li>
                <li>Ability to work collaboratively in a team environment</li>
              </ul>

              <h3>Responsibilities:</h3>
              <ul>
                <li>Design, develop, and maintain web applications using the MERN stack</li>
                <li>Collaborate with front-end developers to integrate user-facing elements</li>
                <li>Implement and manage database systems using MongoDB and MySQL</li>
                <li>Write clean, maintainable, and efficient code</li>
                <li>Participate in code reviews and contribute to team knowledge sharing</li>
              </ul>

              <h3>What We Offer:</h3>
              <ul>
                <li>Competitive salary and benefits</li>
                <li>Opportunities for career advancement and professional development</li>
                <li>Collaborative and innovative work environment</li>
                <li>Work-life balance with flexible work arrangements</li>
              </ul>
            </div>
            <div className="apply-footer">
              <p>Ready to develop cutting-edge applications?</p>
              <Link
                to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                target="_blank"
                className="btn btn-primary"
              >
                Apply for this Position
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)